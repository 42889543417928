import logo from "./logo.svg";
import "./assets/css/bootstrap.min.css";
import "./assets/css/slick.css";
import "./assets/css/animate.css";
import "./assets/css/flaticon.css";
import "./assets/styles/style.scss";
import "./assets/css/responsive.scss";
import React, { Suspense, lazy } from "react";
import "./App.css";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StylesThemeProvider } from "@mui/styles";
import { createMuiTheme } from "./theme/index";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom-v5-compat";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import { Box } from "@mui/material";
import PeopleHookInjector from "@rlmonger/people/dist/hookInjector/hookInjector";
import JobsiteHookWrapper from "@rlmonger/jobsites/dist/components/hookWrapper";
import "react-day-picker/lib/style.css";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import ParamsHook from "@rlmonger/forms/dist/forms/hooks/paramsHook";
import { PrefixProvider } from "@rlmonger/jobsites/dist/hooks/PrefixContext";
import AddGroups from "@rlmonger/groups/dist/groups/addGroup";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import moment from "moment";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FormProvider } from "@rlmonger/forms/dist/contexts/FormContext";

const PEOPLE = "People";
const CODES = "Codes";
const GROUPS = "Groups";
const VEHICLES = "Vehicles";
const JOBSITES = "Jobsites";
const FORMS = "Forms";
const TICKETS = "Tickets";
const REIMBURSEMENTS = "Reimbursements";
const ORDERS = "Orders";
const TIMESHEET = "Timesheet";
const HIRES = "Hires";
const TASKS = "Tasks";
const ADMIN = "Admin";
const TAGS = "Tags";
const NEW_HIRE = "New Hire";
const WIKI = "Wiki";
const DAY_LABOUR = "Day Labour";
const DEPRECATED = "Deprecated (Do Not Use)";

LicenseInfo.setLicenseKey(
  "e3068cf7f8f954ff79f27885ad9b6164Tz04OTUyNCxFPTE3NDYxNTQwNjMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
);

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

const queryClient = new QueryClient();

const App = () => {
  const theme = createMuiTheme({
    compact: true,
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
  });
  moment.locale("au");

  const selectedPermissionGroups = [
    PEOPLE,
    CODES,
    GROUPS,
    VEHICLES,
    JOBSITES,
    FORMS,
    TIMESHEET,
    TASKS,
    ADMIN,
    TAGS,
    NEW_HIRE,
    ORDERS,
    REIMBURSEMENTS,
    TICKETS,
    WIKI,
    DAY_LABOUR,
  ];

  const { user, ...auth } = useAuth();
  const navigate = useNavigate();

  const DashboardLayout = Loadable(
    lazy(() => import("./components/app/layout/dashboard-layout")),
  );
  const Dashboard = Loadable(
    lazy(() => import("./components/app/management/components/dashboard")),
  );
  const Calendar = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/calendar/CalendarContainer"
      ),
    ),
  );
  const LeaveCalendar = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/leaveCalendar/CalendarContainer"
      ),
    ),
  );
  const Notices = Loadable(
    lazy(() => import("./components/noticeboards/ViewNotices")),
  );
  const People = Loadable(
    lazy(() => import("@rlmonger/people/dist/people/peopleViewer")),
  );
  const GroupPermissions = Loadable(
    lazy(() =>
      import("@rlmonger/people/dist/people/permissions/groupPermissions"),
    ),
  );
  const Jobsites = Loadable(
    lazy(() => import("@rlmonger/jobsites/dist/pages/jobsites")),
  );
  const JobsiteDetails = Loadable(
    lazy(() => import("@rlmonger/jobsites/dist/pages/jobsite-details")),
  );
  const AddJobsite = Loadable(
    lazy(() => import("@rlmonger/jobsites/dist/pages/add-jobsite")),
  );
  const ReportingPage = Loadable(lazy(() => import("./pages/Reporting")));
  const ScheduledReportingPage = Loadable(
    lazy(() => import("./pages/ScheduledReport")),
  );
  const Groups = Loadable(
    lazy(() => import("@rlmonger/groups/dist/groups/groups")),
  );
  const Jobcodes = Loadable(
    lazy(() => import("@rlmonger/jobcodes/dist/jobcodes/jobcodes")),
  );
  const HSEQ = Loadable(lazy(() => import("./pages/HSEQ")));
  const TimesheetDashboard = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/timesheetDashboard/TimesheetDashboard"
      ),
    ),
  );
  const PayrollDashboard = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/payrollDashboard/PayrollDashboard"
      ),
    ),
  );
  const ViewTimesheet = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/timesheet")),
  );
  const Review = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/review")),
  );
  const TicketsMatrix = Loadable(
    lazy(() => import("@rlmonger/people/dist/competency/matrix")),
  );
  const StructureReport = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/reports/totalStructureReport")),
  );
  const BannerUpload = Loadable(
    lazy(() => import("./components/banner/ImageUpload")),
  );
  const JobReport = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/reports/jobReport")),
  );
  const OvertimeReport = Loadable(
    lazy(() =>
      import("@rlmonger/timesheet/dist/reports/overtime/overtimeReport"),
    ),
  );
  const ReportEditor = Loadable(
    lazy(() =>
      import("@rlmonger/timesheet/dist/reports/reportEditor/reportEditor"),
    ),
  );
  const UploadReimbursements = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/reimbursements/upload/uploadReimbursement"
      ),
    ),
  );
  const AccountingDashboard = Loadable(
    lazy(() => import("./components/app/management/pages/AdminDashboard")),
  );
  const Allowances = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/allowances/Allowances")),
  );
  const TicketApprovals = Loadable(
    lazy(() =>
      import("@rlmonger/people/dist/competency/requestTickets/TicketApprovals"),
    ),
  );

  const MissingTickets = Loadable(
    lazy(() =>
      import("@rlmonger/people/dist/competency/MissingCompetencies/index"),
    ),
  );

  const ApprovalsDashboard = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/approvals/approvalsDashboard"
      ),
    ),
  );
  const DayLabour = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/dayLabour/DayLabourTable")),
  );
  const ReviewDayLabour = Loadable(
    lazy(() =>
      import(
        "@rlmonger/timesheet/dist/dayLabour/ReviewDayLabour/ReviewDayLabourContainer"
      ),
    ),
  );
  const FormsDashboard = Loadable(
    lazy(() =>
      import("./components/app/management/components/forms/formsDashboard"),
    ),
  );
  const PlantHireCostList = Loadable(
    lazy(() => import("./pages/PlantHireCostList")),
  );
  const Forms = Loadable(
    lazy(() => import("@rlmonger/forms/dist/forms/AdminFormsList")),
  );
  const CreateForm = Loadable(
    lazy(() => import("@rlmonger/forms/dist/forms/buildChecklist")),
  );
  const FormDataViewer = Loadable(
    lazy(() => import("@rlmonger/forms/dist/formDataViewer")),
  );
  const MissingShiftsReport = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/missing_shifts")),
  );
  const NoLoginReport = Loadable(
    lazy(() => import("@rlmonger/timesheet/dist/non_login")),
  );
  const TicketsAdmin = Loadable(
    lazy(() => import("@rlmonger/people/dist/competency/competencyTable")),
  );
  const SupplierManagement = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/SupplierManagement/Suppliers"
      ),
    ),
  );
  const ReviewForms = Loadable(
    lazy(() => import("@rlmonger/forms/dist/ReviewFormsList")),
  );
  const ViewReimbursements = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/reimbursements/viewReimbursements"
      ),
    ),
  );
  const ReviewReimbursements = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/reimbursements/upload/reviewReimbursements"
      ),
    ),
  );
  const PayrollGroups = Loadable(
    lazy(() => import("@rlmonger/groups/dist/payrollGroups/PayrollGroups")),
  );
  const NewHireApprovals = Loadable(
    lazy(() => import("./pages/NewHireApprovals")),
  );
  const NewHireForm = Loadable(lazy(() => import("./pages/NewHireForm")));
  const Roles = Loadable(lazy(() => import("./pages/Roles")));
  const EmployeeAllocator = Loadable(
    lazy(() => import("./pages/EmployeeAllocator")),
  );
  const PinnedForms = Loadable(lazy(() => import("./pages/PinnedForms")));
  const RequiredForms = Loadable(lazy(() => import("./pages/RequiredForms")));
  const RequiredTickets = Loadable(
    lazy(() => import("./pages/RequiredTickets")),
  );
  const Cigipedia = Loadable(lazy(() => import("./pages/CigipediaLayout")));
  const AllShifts = Loadable(lazy(() => import("./pages/AllShifts")));
  const CompanySettings = Loadable(
    lazy(() => import("./components/settings/CompanySettings")),
  );
  const POEditor = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/purchaseOrders/orderContainer"
      ),
    ),
  );
  const OrderCreation = Loadable(
    lazy(() =>
      import(
        "./components/app/management/components/stocklist/stockOrders/orderCreation"
      ),
    ),
  );
  const TagManager = Loadable(lazy(() => import("./pages/TagManager")));
  const OrderDashboard = Loadable(lazy(() => import("./components/orders")));
  const PurchaseOrderManager = Loadable(
    lazy(() => import("./components/orders/manage")),
  );
  const PurchaseOrderReviews = Loadable(
    lazy(() => import("./components/orders/review")),
  );
  const PurchaseOrderInvoices = Loadable(
    lazy(() => import("./components/orders/invoices")),
  );
  const ReviewLeaveRequests = Loadable(
    lazy(() => import("./components/leave/ReviewLeaveRequests")),
  );
  const UploadTickets = Loadable(lazy(() => import("./pages/UploadTickets")));
  const AdminLogin = Loadable(lazy(() => import("./pages/AdminLogin/index")));
  const CompleteForm = Loadable(lazy(() => import("./pages/CompleteForm")));
  const Applications = Loadable(
    lazy(() => import("./pages/Applications/Applications")),
  );
  const ApplicationUploads = Loadable(
    lazy(() => import("./pages/Applications/ApplicationUploads")),
  );
  const PushNotificationsBuilder = Loadable(
    lazy(() => import("./pages/PushNotificationsBuilder")),
  );
  const PushNotificationsHistory = Loadable(
    lazy(() => import("./pages/PushNotificationHistory")),
  );

  return (
    <ThemeProvider theme={theme}>
      <StylesThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          maxSnack={3}
        >
          <LocalizationProvider
            dateLibInstance={moment}
            dateAdapter={AdapterMoment}
          >
            {auth.isInitialized ? (
              <AuthGuard>
                <QueryClientProvider client={queryClient}>
                  <WebSocketProvider>
                    <DashboardLayout>
                      <Box sx={{ p: 1 }}>
                        <PrefixProvider prefix={""}>
                          <Routes>
                            <Route exact path="/" element={<Dashboard />} />
                            <Route
                              path="/people"
                              element={
                                <PeopleHookInjector
                                  token={window.localStorage.getItem(
                                    "authToken",
                                  )}
                                  children={
                                    <People
                                      selectedPermissionGroups={
                                        selectedPermissionGroups
                                      }
                                      changeTab={(tab) => {
                                        if (tab === "groupPermissions") {
                                          navigate("groups_permissions");
                                        }
                                      }}
                                      permissions={user?.permissions}
                                    />
                                  }
                                />
                              }
                            />
                            <Route
                              path="/tag_manager"
                              element={
                                <PeopleHookInjector
                                  token={window.localStorage.getItem(
                                    "authToken",
                                  )}
                                  children={
                                    <>
                                      {user?.permissions && (
                                        <TagManager
                                          permissions={user?.permissions}
                                        />
                                      )}
                                    </>
                                  }
                                />
                              }
                            />
                            <Route
                              path="/groups_permissions"
                              element={
                                <GroupPermissions
                                  selectedPermissionGroups={
                                    selectedPermissionGroups
                                  }
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/plant-hire-cost-list"
                              element={<PlantHireCostList />}
                            />
                            <Route
                              path="/groups"
                              element={
                                <Groups
                                  changeTab={(tab) => {
                                    if (tab === "addGroup") {
                                      navigate("add-group");
                                    }
                                  }}
                                />
                              }
                            />
                            <Route
                              path="/view_jobsites"
                              element={
                                <JobsiteHookWrapper
                                  permissions={user?.permissions}
                                  token={localStorage.getItem("authToken")}
                                  children={<Jobsites />}
                                />
                              }
                            />
                            <Route
                              path="/review_forms"
                              element={<ReviewForms />}
                            />
                            <Route
                              path="/review_reimbursements"
                              element={<ReviewReimbursements />}
                            />
                            <Route
                              path="/upload_reimbursement"
                              element={<UploadReimbursements />}
                            />
                            <Route
                              path="/push-notifications"
                              element={<PushNotificationsBuilder />}
                            />
                            <Route
                              path="/push-notification-history"
                              element={<PushNotificationsHistory />}
                            />
                            <Route
                              path="/review_day_labour"
                              element={<ReviewDayLabour />}
                            />
                            <Route
                              path="/reporting"
                              element={<ReportingPage />}
                            />
                            <Route
                              path="/scheduled-reports/:id"
                              element={<ScheduledReportingPage />}
                            />
                            <Route path="/day_labour" element={<DayLabour />} />
                            <Route path="/roles" element={<Roles />} />
                            <Route
                              path="/missing_shifts_report"
                              element={<MissingShiftsReport />}
                            />
                            <Route
                              path="/no_login_report"
                              element={<NoLoginReport />}
                            />
                            <Route
                              path="/overtime_report"
                              element={<OvertimeReport />}
                            />
                            <Route
                              path="/structure_report"
                              element={<StructureReport />}
                            />
                            <Route path="/job_report" element={<JobReport />} />
                            <Route
                              path="/applications"
                              element={<Applications />}
                            />
                            <Route
                              path="/application-uploads"
                              element={<ApplicationUploads />}
                            />
                            <Route
                              path="/order_creation"
                              element={<OrderCreation />}
                            />
                            <Route
                              path="/view_reimbursements"
                              element={<ViewReimbursements />}
                            />
                            <Route
                              path="/cigipedia"
                              element={
                                <Cigipedia permissions={user?.permissions} />
                              }
                            />
                            <Route path="/allshifts" element={<AllShifts />} />
                            <Route
                              path="/report_editor"
                              element={<ReportEditor />}
                            />

                            <Route
                              path="/review-leave-requests"
                              element={<ReviewLeaveRequests />}
                            />

                            <Route
                              path="/accounting_dashboard"
                              element={<AccountingDashboard />}
                            />
                            <Route
                              path="/admin_login"
                              element={<AdminLogin />}
                            />
                            <Route
                              path="/approvals"
                              element={<ApprovalsDashboard user={user} />}
                            />
                            <Route
                              path="/company_settings"
                              element={<CompanySettings user={user} />}
                            />
                            <Route
                              path="/notices"
                              element={<Notices open={true} user={user} />}
                            />
                            <Route
                              path="/forms/:id"
                              element={
                                <ParamsHook children={<CompleteForm />} />
                              }
                            />
                            <Route
                              path="/forms_selection"
                              element={
                                <ParamsHook
                                  children={
                                    <FormProvider>
                                      <FormsDashboard />
                                    </FormProvider>
                                  }
                                />
                              }
                            />
                            <Route
                              path="/forms"
                              element={
                                <Forms
                                  navigate={(tab) => {
                                    navigate(`${tab}`);
                                  }}
                                  editForm={(endpoint, form) => {
                                    navigate(`buildform/${form.formid}`);
                                  }}
                                />
                              }
                            />
                            <Route
                              exact
                              path="/buildform"
                              element={
                                <ParamsHook
                                  children={
                                    <CreateForm
                                      permissions={user?.permissions}
                                      selectedPermissionGroups={
                                        selectedPermissionGroups
                                      }
                                    />
                                  }
                                />
                              }
                            />
                            <Route
                              exact
                              path="/buildform/:formid"
                              element={
                                <ParamsHook
                                  children={
                                    <CreateForm
                                      permissions={user?.permissions}
                                      selectedPermissionGroups={
                                        selectedPermissionGroups
                                      }
                                    />
                                  }
                                />
                              }
                            />

                            <Route
                              path="/add-group"
                              element={
                                <AddGroups
                                  changeTab={(tab) => {
                                    if (tab == "groups") {
                                      navigate("/groups");
                                    }
                                  }}
                                />
                              }
                            />

                            <Route
                              path="/form_data_viewer"
                              element={<FormDataViewer />}
                            />
                            <Route
                              path="/allowances"
                              element={<Allowances />}
                            />
                            <Route
                              path="/timesheet"
                              element={
                                <ViewTimesheet
                                  admin={false}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/admin_timesheet"
                              element={
                                <ViewTimesheet
                                  admin={true}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/cigfab_timesheet"
                              element={
                                <ViewTimesheet
                                  startDay={3}
                                  admin={false}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/cigfab_admin_timesheet"
                              element={
                                <ViewTimesheet
                                  startDay={3}
                                  admin={true}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/review"
                              element={
                                <Review permissions={user?.permissions} />
                              }
                            />
                            <Route
                              path="/payroll_dashboard"
                              element={<PayrollDashboard user={user} />}
                            />
                            <Route path="/po_editor" element={<POEditor />} />
                            <Route
                              path="/banner_upload"
                              element={<BannerUpload />}
                            />
                            <Route
                              path="/jobcodes"
                              element={
                                <Jobcodes
                                  toolbarStyles={{ mb: 2 }}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/jobsites"
                              element={
                                user?.permissions && (
                                  <JobsiteHookWrapper
                                    permissions={user?.permissions}
                                    token={localStorage.getItem("authToken")}
                                    children={<Jobsites />}
                                  />
                                )
                              }
                            />
                            <Route
                              path="/jobsite/:id/:primary?/:secondary?"
                              element={
                                user?.permissions && (
                                  <JobsiteHookWrapper
                                    permissions={user?.permissions}
                                    token={localStorage.getItem("authToken")}
                                    children={<JobsiteDetails />}
                                  />
                                )
                              }
                            />
                            <Route
                              path="/add-jobsite"
                              element={
                                user?.permissions && (
                                  <JobsiteHookWrapper
                                    permissions={user?.permissions}
                                    token={localStorage.getItem("authToken")}
                                    children={<AddJobsite />}
                                  />
                                )
                              }
                            />
                            <Route
                              path="/tickets_matrix"
                              element={<TicketsMatrix />}
                            />

                            <Route
                              path="/upload-tickets"
                              element={<UploadTickets />}
                            />
                            <Route
                              path="/missing-tickets"
                              element={<MissingTickets />}
                            />
                            <Route
                              path="/tickets_admin"
                              element={<TicketsAdmin />}
                            />
                            <Route
                              path="/supplier_management"
                              element={<SupplierManagement />}
                            />
                            <Route
                              path="/pinned_forms"
                              element={<PinnedForms />}
                            />
                            <Route
                              path="/required_forms"
                              element={<RequiredForms />}
                            />
                            <Route
                              path="/required_tickets"
                              element={<RequiredTickets />}
                            />
                            <Route
                              path="/reports_dashboard"
                              element={<ReportingPage user={user} />}
                            />
                            <Route
                              path="/ticket_approvals"
                              element={<TicketApprovals user={user} />}
                            />
                            <Route
                              path="/new_hire_approvals"
                              element={<NewHireApprovals user={user} />}
                            />
                            <Route
                              path="/new_hire_form"
                              element={<NewHireForm user={user} />}
                            />
                            <Route
                              path="/timesheet"
                              element={
                                <ViewTimesheet
                                  admin={false}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/admin_timesheet"
                              element={
                                <ViewTimesheet
                                  admin={true}
                                  permissions={user?.permissions}
                                />
                              }
                            />
                            <Route
                              path="/review"
                              element={
                                <Review permissions={user?.permissions} />
                              }
                            />
                            <Route
                              path="/payroll_dashboard"
                              element={<PayrollDashboard user={user} />}
                            />
                            <Route
                              path="/payroll_groups"
                              element={<PayrollGroups />}
                            />
                            <Route
                              path="/timesheet_dashboard"
                              element={<TimesheetDashboard user={user} />}
                            />
                            <Route path="/hseq" element={<HSEQ />} />
                            <Route path="/calendar" element={<Calendar />} />
                            <Route
                              path="/leave-calendar"
                              element={<LeaveCalendar />}
                            />
                            <Route
                              path="/employee_allocator"
                              element={<EmployeeAllocator />}
                            />
                            <Route
                              path="/orders/invoices/:id?"
                              element={<PurchaseOrderInvoices />}
                            />
                            <Route
                              path="/orders/review"
                              element={<PurchaseOrderReviews />}
                            />
                            <Route
                              path="/orders/manage"
                              element={<PurchaseOrderManager />}
                            />
                            <Route
                              path="/orders"
                              element={<OrderDashboard />}
                            />
                          </Routes>
                        </PrefixProvider>
                      </Box>
                    </DashboardLayout>
                  </WebSocketProvider>
                </QueryClientProvider>
              </AuthGuard>
            ) : (
              <SplashScreen />
            )}
          </LocalizationProvider>
        </SnackbarProvider>
      </StylesThemeProvider>
    </ThemeProvider>
  );
};

export default App;
